import React, { useState, useEffect, useRef } from 'react';
import {withRouter } from "react-router-dom";
import {post,get} from '../../helpers/api_helper';
import Loader from '../../component/Loader';
import {GET_ALL_SETTING,POST_UPDATE_SETTING} from '../../helpers/url_helper';
import { toast } from 'react-toastify';
import SimpleReactValidator from 'simple-react-validator';
import {Helmet} from "react-helmet";
import { Link } from "react-router-dom";

const ChangePassword = props => {

    const [isLoader, setLoader] = useState(0);
    const simpleValidator = useRef(new SimpleReactValidator());
    const [ButtonDisabled, SetButtonDisabled] = useState(false);
    const [form_inputs, setInputs] = useState();
    const [, forceUpdate] = useState();

    useEffect(() => {
        async function get_detaile() {
            await get_app_setting();
            setLoader(1);
        }
        get_detaile();
    }, [])

    const get_app_setting = async () => {
        var res_data = await get(GET_ALL_SETTING+'?type=common-setting');
        if (res_data.status) {
            await setInputs(res_data.data);
        } else {
            toast.error(res_data.message);
        }
    }

    // form submit event
    const handleSubmit = async (event) => {
        event.preventDefault();
        const formValid = simpleValidator.current.allValid()
        if (!formValid) {
            simpleValidator.current.showMessages(true);
            forceUpdate(1);
        } else {
            SetButtonDisabled(true);
           
            var res_data = await post(POST_UPDATE_SETTING, form_inputs, {});
            if (res_data.status) {
                await get_app_setting();
                toast(res_data.message);
            } else {
                toast.error(res_data.message);
            }
            SetButtonDisabled(false);
        }
    }

    // input text change handler
    const handleInputChange = (event) => {
        event.persist();
        if (event.target.name === 'website_login_enable') {
            setInputs(inputs => ({ ...form_inputs, [event.target.name]: (event.target.checked) ? '1' : '0' }));
        } else {
            setInputs(inputs => ({ ...form_inputs, [event.target.name]: event.target.value }));
        }
    }

    if (!isLoader) {
        return (<Loader></Loader>);
    }

    return (
        <>  
            <Helmet>
                <meta charSet="utf-8" />
                <title>Bizbaron | Common Setting</title>
            </Helmet>
            
            <div className='row mt-3'>
                <div className='col-sm-6'>
                    <div className='card'>
                        <div class="card-header">
                            <h6 className='m-0'>Common Setting</h6>
                        </div>
                        <div className='card-body'>
                            <form className="av-invalid" onSubmit={handleSubmit}>
                                <div className="form-group mb-3">
                                    <div className="row">
                                        <div className="col-6">
                                            <label className="form-label text-capitalize mt-1">Website Login Enable</label>
                                        </div>
                                        <div className="col-6">
                                            <label class="switch">
                                                <input name="website_login_enable" onChange={handleInputChange} defaultChecked={parseInt(form_inputs.website_login_enable)} type="checkbox" />
                                                <span class="slider round"></span>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                
                                <div className="text-center mt-4">
                                    <button disabled={ButtonDisabled} type="submit" className='btn btn-primary c-btn-primary pt-1 pb-1 pl-3 pr-3'>{ButtonDisabled && <i className="fa fa-spinner me-2 fa-spin"></i>} Update</button>
                                    <Link to="/setting" className='btn btn-danger c-btn-danger ml-3 pt-1 pb-1 pl-3 pr-3'>Cancel</Link>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </>    
    );
}
export default withRouter(ChangePassword)
