import React, { useState, useEffect, useRef } from 'react';
import { withRouter, useHistory, Link } from "react-router-dom";
import { GET_COUNTRIES, GET_USER_DETAIL, GET_ADD_EDIT_USER } from '../../helpers/url_helper';
import { post, get } from '../../helpers/api_helper';
import { toast } from 'react-toastify';
import SimpleReactValidator from 'simple-react-validator';
import Loader from '../../component/Loader';

const Preferences = props => {
	let history = useHistory();

	const [isLoader, setLoader] = useState(0);
	const simpleValidator = useRef(new SimpleReactValidator());
	const [, forceUpdate] = useState();
	const [ActiveTab, setActiveTab] = useState(1);
	const [CountriesListe, SetCountriesListe] = useState([]);
	const [ButtonDisabled, SetButtonDisabled] = useState(false);


	const [UserInfo, SetUserInfo] = useState({
		userId: '',
		companyId: 0,
		firstName: '',
		lastName: '',
		emailId: '',
		mobileNumber: '',
		password: '',
		countryId: '',
		viewProducts: 0,
		addNewProducts: 0,
		editProductDetails: 0,
		deleteProducts: 0,
		viewCatalogueHistory: 0,
		createAndEditCatalogues: 0,
		deleteCatalogues: 0,
		showBuyerDetails: 0,
		viewCosting: 0,
		viewPackaging: 0,
		editCosting: 0,
		editPackaging: 0,
		qRLabelTemplate: 0,
		scanQRSettings: 0,
		viewQuotation : 0,
		createQuotation : 0,
		editQuotation : 0,
		deleteQuotation : 0,
		viewInvoice : 0,
		createInvoice : 0,
		editInvoice : 0,
		deleteInvoice : 0,
	});

	useEffect(() => {
		async function get_detaile() {
			await get_countries();
			if (props.userId) {
				await get_user_info();
			}else{
				SetUserInfo(inputs => ({ ...UserInfo, ['companyId']: props.companyId }));
			}
			setLoader(1);
		}
		get_detaile();
	}, [props])

	const get_countries = async () => {
		var res_data = await get(GET_COUNTRIES);
		if (res_data.status) {
			SetCountriesListe(res_data.data.countries_list);
		} else {
			toast.error(res_data.message);
			history.push("/");
		}
	}

	const get_user_info = async () => {
		var res_data = await post(GET_USER_DETAIL, { userId: props.userId });
		if (res_data.status) {
			SetUserInfo(res_data.data.user_detail);
		} else {
			toast.error(res_data.message);
			history.push("/");
		}
	}

	const handleInputChange = (event) => {
		if (
			event.target.name === 'firstName' ||
			event.target.name === 'lastName' ||
			event.target.name === 'emailId' ||
			event.target.name == 'password' ||
			event.target.name == 'countryId' ||
			event.target.name === 'mobileNumber'
		) {
			SetUserInfo(inputs => ({ ...UserInfo, [event.target.name]: event.target.value }));
		} else {
			SetUserInfo(inputs => ({ ...UserInfo, [event.target.name]: (event.target.checked) ? 1 : 0 }));
		}
	}

	// form submit event
	const handleSubmit = async (event) => {
		event.preventDefault();
		const formValid = simpleValidator.current.allValid()
		if (!formValid) {
			simpleValidator.current.showMessages(true);
			forceUpdate(1);
		} else {
			SetButtonDisabled(true);
			var res_data = await post(GET_ADD_EDIT_USER, UserInfo);
			if (res_data.status) {
				toast(res_data.message);
				props.get_user_list();
				clear_form();
				window.$('#UserAddEditModal').modal('hide');
			} else {
				toast.error(res_data.message);
			}
			SetButtonDisabled(false);
		}
	}

	const clear_form = () => {
		SetUserInfo({
			userId: '',
			firstName: '',
			lastName: '',
			emailId: '',
			password: '',
			mobileNumber: '',
			countryId: '',
			viewProducts: 0,
			addNewProducts: 0,
			editProductDetails: 0,
			deleteProducts: 0,
			viewCatalogueHistory: 0,
			createAndEditCatalogues: 0,
			deleteCatalogues: 0,
			showBuyerDetails: 0,
			viewCosting: 0,
			viewPackaging: 0,
			editCosting: 0,
			editPackaging: 0,
			qRLabelTemplate: 0,
			scanQRSettings: 0,
		})
	}
	return (
		<>
			<div className="modal fade" id="UserAddEditModal" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
				<div className="modal-dialog modal-dialog-centered modal-lg" role="document" style={{ "maxWidth": "700px" }}>
					<div className="modal-content">
						{!isLoader && <Loader />}
						{isLoader && <form onSubmit={handleSubmit}>
							<div className="modal-body p-4">
								<div className="horizontal">
									<ul>
										<li><a href="javascript:void(0)" onClick={() => { setActiveTab(1) }} className={`${ActiveTab === 1 ? "active" : ""}`}>Details</a></li>
										<li><a href="javascript:void(0)" onClick={() => { setActiveTab(2) }} className={`${ActiveTab === 2 ? "active" : ""}`}>User permissions</a></li>
									</ul>
								</div>
								<div className='row'>
									<div className='col-12 mt-2'>
										<p className='mb-0'>Add users to the platform and define their permissions of usage</p>
									</div>
								</div>
								{ActiveTab === 1 && <>
									<div className='row mt-3'>
										<div className='col-6'>
											<div className="form-group mb-4">
												<label><small className='text-danger'>*</small> First Name</label>
												<input type="text" name='firstName' value={UserInfo.firstName} onChange={handleInputChange} className="form-control" placeholder="" />
												{simpleValidator.current.message('first name', UserInfo.firstName, 'required')}
											</div>
										</div>
										<div className='col-sm-6'>
											<div className="form-group mb-4">
												<label><small className='text-danger'>*</small> Last Name</label>
												<input type="text" name='lastName' value={UserInfo.lastName} onChange={handleInputChange} className="form-control" placeholder="" />
												{simpleValidator.current.message('lastName', UserInfo.lastName, 'required')}
											</div>
										</div>
										<div className='col-sm-12'>
											<div className="form-group mb-4">
												<label><small className='text-danger'>*</small> Email Id</label>
												<input type="text" name='emailId' value={UserInfo.emailId} onChange={handleInputChange} className="form-control" placeholder="" disabled={(UserInfo.userId > 0) ? true : false} />
												{/* {UserInfo.userId <= 0 && simpleValidator.current.message('email id', UserInfo.emailId, 'required|email')} */}
											</div>
										</div>
										<div className='col-sm-12'>
											<div className="form-group mb-4">
												<label>{UserInfo.userId <= 0 && <small className='text-danger'>*</small>} Password</label>
												<input type="password" name='password' value={UserInfo.password} onChange={handleInputChange} className="form-control" placeholder="" />
												{/* {UserInfo.userId <= 0 && simpleValidator.current.message('password', UserInfo.password, 'required')} */}
											</div>
										</div>
										<div className='col-12'>
											<label>Mobile number</label>
										</div>
										<div className='col-3 mb-3'>
											<select className="form-control" name='countryId' value={UserInfo.countryId} onChange={handleInputChange}>
												<option value="">Select Phone Code</option>
												{CountriesListe && Object.entries(CountriesListe).map(([key, value]) => (
													<option key={key} value={value.countryId}>{value.countryCode} (+{value.phoneCode})</option>
												))}
											</select>
											{simpleValidator.current.message('phone code', UserInfo.countryId, 'required')}
										</div>
										<div className='col-9 mb-3'>
											<input type='text' value={UserInfo.mobileNumber} name="mobileNumber" onChange={handleInputChange} className="form-control" />
											{simpleValidator.current.message('mobile number', UserInfo.mobileNumber, 'required')}
										</div>
									</div>
								</>}
								{ActiveTab === 2 && <>
									<div className='row mt-3'>
										<div className='col-12 mb-2'>
											<h6><b>Product</b></h6>
										</div>
										<div className='col-1 mb-3'>
											<label className="switch mt-2">
												<input type="checkbox" name='viewProducts' onChange={handleInputChange} checked={(UserInfo.viewProducts > 0) ? true : false} />
												<span className="slider round"></span>
											</label>
										</div>
										<div className='col-5 mb-3'>
											<b> View Products</b><br />
											<small className='mb-0'>User can view products</small>
										</div>
										<div className='col-1 mb-3'>
											<label className="switch mt-2">
												<input type="checkbox" name='addNewProducts' onChange={handleInputChange} checked={(UserInfo.addNewProducts > 0) ? true : false} />
												<span className="slider round"></span>
											</label>
										</div>
										<div className='col-5 mb-3'>
											<b> Add new products</b><br />
											<small className='mb-0'>User can create a new category of products</small>
										</div>
										<div className='col-1 mb-3'>
											<label className="switch mt-2">
												<input type="checkbox" name='editProductDetails' onChange={handleInputChange} checked={(UserInfo.editProductDetails > 0) ? true : false} />
												<span className="slider round"></span>
											</label>
										</div>
										<div className='col-5 mb-3'>
											<b> Edit product details</b><br />
											<small className='mb-0'>User can edit the details of selected fields only</small>
										</div>
										<div className='col-1 mb-3'>
											<label className="switch mt-2">
												<input type="checkbox" name='deleteProducts' onChange={handleInputChange} checked={(UserInfo.deleteProducts > 0) ? true : false} />
												<span className="slider round"></span>
											</label>
										</div>
										<div className='col-5 mb-3'>
											<b> Delete products</b><br />
											<small className='mb-0'>User can delete products from the platform</small>
										</div>
									</div>
									<hr />
									<div className='row mt-3'>
										<div className='col-12 mb-2'>
											<h6><b>Catalogue</b></h6>
										</div>
										<div className='col-1 mb-2'>
											<label className="switch mt-2">
												<input type="checkbox" name='viewCatalogueHistory' onChange={handleInputChange} checked={(UserInfo.viewCatalogueHistory > 0) ? true : false} />
												<span className="slider round"></span>
											</label>
										</div>
										<div className='col-5 mb-3'>
											<b> View catalogue history</b><br />
											<small className='mb-0'>User can view catalogue history on the platform</small>
										</div>
										<div className='col-1 mb-2'>
											<label className="switch mt-2">
												<input type="checkbox" name='createAndEditCatalogues' onChange={handleInputChange} checked={(UserInfo.createAndEditCatalogues > 0) ? true : false} />
												<span className="slider round"></span>
											</label>
										</div>
										<div className='col-5 mb-3'>
											<b> Create and edit catalogues</b><br />
											<small className='mb-0'>User can create new catalogues and edit their details</small>
										</div>
										<div className='col-1 mb-2'>
											<label className="switch mt-2">
												<input type="checkbox" name='deleteCatalogues' onChange={handleInputChange} checked={(UserInfo.deleteCatalogues > 0) ? true : false} />
												<span className="slider round"></span>
											</label>
										</div>
										<div className='col-5 mb-3'>
											<b> Delete catalogues</b><br />
											<small className='mb-0'>User can delete any catalogue</small>
										</div>
										<div className='col-1 mb-2'>
											<label className="switch mt-2">
												<input type="checkbox" name='showBuyerDetails' onChange={handleInputChange} checked={(UserInfo.showBuyerDetails > 0) ? true : false} />
												<span className="slider round"></span>
											</label>
										</div>
										<div className='col-5 mb-3'>
											<b> Show Buyer Details</b><br />
											<small className='mb-0'>User can see buyer details</small>
										</div>
									</div>
									{/* <hr /> */}
									<div className='row mt-3 none'>
										<div className='col-12 mb-2'>
											<h6><b>Costing</b></h6>
										</div>
										<div className='col-1 mb-2'>
											<label className="switch mt-2">
												<input type="checkbox" name='viewCosting' onChange={handleInputChange} checked={(UserInfo.viewCosting > 0) ? true : false} />
												<span className="slider round"></span>
											</label>
										</div>
										<div className='col-5 mb-3'>
											<b> View Costing</b><br />
											<small className='mb-0'>User can view product costing</small>
										</div>
										<div className='col-1 mb-2'>
											<label className="switch mt-2">
												<input type="checkbox" name='viewPackaging' onChange={handleInputChange} checked={(UserInfo.viewPackaging > 0) ? true : false} />
												<span className="slider round"></span>
											</label>
										</div>
										<div className='col-5 mb-3'>
											<b> View Packaging</b><br />
											<small className='mb-0'>User can view packaging details</small>
										</div>
										<div className='col-1 mb-2'>
											<label className="switch mt-2">
												<input type="checkbox" name='editCosting' onChange={handleInputChange} checked={(UserInfo.editCosting > 0) ? true : false} />
												<span className="slider round"></span>
											</label>
										</div>
										<div className='col-5 mb-3'>
											<b> Edit Costing</b><br />
											<small className='mb-0'>User can edit product costing.</small>
										</div>
										<div className='col-1 mb-2'>
											<label className="switch mt-2">
												<input type="checkbox" name='editPackaging' onChange={handleInputChange} checked={(UserInfo.editPackaging > 0) ? true : false} />
												<span className="slider round"></span>
											</label>
										</div>
										<div className='col-5 mb-3'>
											<b> Edit Packaging</b><br />
											<small className='mb-0'>User can edit packaging</small>
										</div>
									</div>
									<hr />
									<div className='row mt-3'>
										<div className='col-12 mb-2'>
											<h6><b>Label & QR</b></h6>
										</div>
										<div className='col-1 mb-2'>
											<label className="switch mt-2">
												<input type="checkbox" name='qRLabelTemplate' onChange={handleInputChange} checked={(UserInfo.qRLabelTemplate > 0) ? true : false} />
												<span className="slider round"></span>
											</label>
										</div>
										<div className='col-5 mb-3'>
											<b> QR Label template</b><br />
											<small className='mb-0'>User can change default template</small>
										</div>
										<div className='col-1 mb-2'>
											<label className="switch mt-2">
												<input type="checkbox" name='scanQRSettings' onChange={handleInputChange} checked={(UserInfo.scanQRSettings > 0) ? true : false} />
												<span className="slider round"></span>
											</label>
										</div>
										<div className='col-5 mb-3'>
											<b> Scan QR Settings</b><br />
											<small className='mb-0'>User can enable public QR & change attributes</small>
										</div>
									</div>
									<hr />
									<div className='row mt-3'>
										<div className='col-12 mb-2'>
											<h6><b>Quotation</b></h6>
										</div>
										<div className='col-1 mb-2'>
											<label className="switch mt-2">
												<input type="checkbox" name='viewQuotation' onChange={handleInputChange} checked={(UserInfo.viewQuotation > 0) ? true : false} />
												<span className="slider round"></span>
											</label>
										</div>
										<div className='col-5 mb-3'>
											<b> View Quotation</b><br />
											<small className='mb-0'>User can view quotations</small>
										</div>
										<div className='col-1 mb-2'>
											<label className="switch mt-2">
												<input type="checkbox" name='createQuotation' onChange={handleInputChange} checked={(UserInfo.createQuotation > 0) ? true : false} />
												<span className="slider round"></span>
											</label>
										</div>
										<div className='col-5 mb-3'>
											<b> Create Quotation</b><br />
											<small className='mb-0'>User can create quotation</small>
										</div>
										<div className='col-1 mb-2'>
											<label className="switch mt-2">
												<input type="checkbox" name='editQuotation' onChange={handleInputChange} checked={(UserInfo.editQuotation > 0) ? true : false} />
												<span className="slider round"></span>
											</label>
										</div>
										<div className='col-5 mb-3'>
											<b> Edit Quotation</b><br />
											<small className='mb-0'>User can edit quotation</small>
										</div>
										<div className='col-1 mb-2'>
											<label className="switch mt-2">
												<input type="checkbox" name='deleteQuotation' onChange={handleInputChange} checked={(UserInfo.deleteQuotation > 0) ? true : false} />
												<span className="slider round"></span>
											</label>
										</div>
										<div className='col-5 mb-3'>
											<b> Delete Quotation</b><br />
											<small className='mb-0'>User can delete quotation</small>
										</div>
									</div>
									<hr />
									<div className='row mt-3'>
										<div className='col-12 mb-2'>
											<h6><b>Invoice</b></h6>
										</div>
										<div className='col-1 mb-2'>
											<label className="switch mt-2">
												<input type="checkbox" name='viewInvoice' onChange={handleInputChange} checked={(UserInfo.viewInvoice > 0) ? true : false} />
												<span className="slider round"></span>
											</label>
										</div>
										<div className='col-5 mb-3'>
											<b> View Invoice</b><br />
											<small className='mb-0'>User can view invoice</small>
										</div>
										<div className='col-1 mb-2'>
											<label className="switch mt-2">
												<input type="checkbox" name='createInvoice' onChange={handleInputChange} checked={(UserInfo.createInvoice > 0) ? true : false} />
												<span className="slider round"></span>
											</label>
										</div>
										<div className='col-5 mb-3'>
											<b> Create Invoice</b><br />
											<small className='mb-0'>User can create invoice</small>
										</div>
										<div className='col-1 mb-2'>
											<label className="switch mt-2">
												<input type="checkbox" name='editInvoice' onChange={handleInputChange} checked={(UserInfo.editInvoice > 0) ? true : false} />
												<span className="slider round"></span>
											</label>
										</div>
										<div className='col-5 mb-3'>
											<b> Edit Invoice</b><br />
											<small className='mb-0'>User can edit invoice</small>
										</div>
										<div className='col-1 mb-2'>
											<label className="switch mt-2">
												<input type="checkbox" name='deleteInvoice' onChange={handleInputChange} checked={(UserInfo.deleteInvoice > 0) ? true : false} />
												<span className="slider round"></span>
											</label>
										</div>
										<div className='col-5 mb-3'>
											<b> Delete Invoice</b><br />
											<small className='mb-0'>User can delete invoice</small>
										</div>
									</div>
								</>}
								<hr />
								<div className='row'>
									<div className='col-sm-6'>
										<button type='button' className='btn btn-light btn-sm pl-4 pr-4 pt-1 pb-1' onClick={() => { window.$('#UserAddEditModal').modal('hide'); clear_form();}} >Cancel</button>
									</div>
									<div className='col-sm-6 text-right'>
										<button type='submit' disabled={ButtonDisabled} className='btn btn-primary btn-xs pl-4 pr-4 pt-1 pb-1'>{ButtonDisabled && <i className="fa fa-spinner me-2 fa-spin"></i>} Save</button>
									</div>
								</div>
							</div>
						</form>}
					</div>
				</div>
			</div>

		</>
	);
}

export default withRouter(Preferences);
