
// auth
export const POST_LOGIN = "/auth/login"
export const POST_VERIFY_OTP = "/auth/verify-otp"
export const POST_FORGOT_PASSWORD = "/auth/forgot-password"
export const POST_RESET_PASSWORD = "/auth/reset-password"

export const GET_DASHBOARD_DATA = "/dashboard/dashboard"

//user
export const POST_CHANGE_PASSWORD = "/dashboard/change-password"

//setting
export const GET_ALL_SETTING = "/setting/get-all-setting"
export const POST_UPDATE_SETTING = "/setting/update-setting"

//customers
export const POST_CUSTOMERS_LIST = process.env.REACT_APP_API_URL+"customers/get-all-customers"
export const DELETE_CUSTOMER = "/customers/delete"
export const POST_UPDATE_CUSTOMER_STATUS = "/customers/update-status"
export const POST_UPDATE_CUSTOMER_DETAIL = "/customers/detail"
export const POST_ADD_EDIT_CUSTOMER_DETAIL = "/customers/add-edit"
export const POST_CUSTOMER_USER_LIST = "/customers/get-user-list"
export const GET_ADD_EDIT_USER = "/customers/user-add-edit"
export const GET_USER_DETAIL = "/customers/get-user-detail"
export const POST_DELETE_USER = "/customers/delete-user"

// common
export const POST_GET_ALL_CURRENCIES = "/common/get-all-currencies"
export const GET_COUNTRIES = "/common/countries"
