import React, { useState, useEffect, useRef } from 'react';
import { withRouter } from "react-router-dom";
import { post, get } from '../../helpers/api_helper';
import { POST_UPDATE_CUSTOMER_DETAIL, POST_ADD_EDIT_CUSTOMER_DETAIL, POST_GET_ALL_CURRENCIES, POST_CUSTOMER_USER_LIST, POST_DELETE_USER } from '../../helpers/url_helper';
import Loader from '../../component/Loader';
import { img_view, utc_to_local } from '../../helpers/Helper';
import { toast } from 'react-toastify';
import SimpleReactValidator from 'simple-react-validator';
import { Helmet } from "react-helmet";
import { Link, useHistory } from "react-router-dom";
import UsersModal from './UsersModal';


const ChangePassword = props => {
    let history = useHistory();

    const [isLoader, setLoader] = useState(0);
    const simpleValidator = useRef(new SimpleReactValidator());
    const [ButtonDisabled, SetButtonDisabled] = useState(false);
    const [CurrenciesList, SetCurrenciesList] = useState([]);
    const [UserList, SetUserList] = useState([]);
    const [form_inputs, setInputs] = useState({
        'companyId': 0,
        'logo': '',
        'coverImage': '',
        'companyName': '',
        'companyBrand': '',
        'workingHoursTime': '',
        'name': '',
        'emailId': '',
        'otherReference': '',
        'address': '',
        'countryOfOrigin': '',
        'exporterReferenceID': '',
        'termsAndConditions': '',
        'bankName': '',
        'accountNumber': '',
        'IFSCCode': '',
        'SWIFTCode': '',
        'bankAddress': '',
    });
    const [title, setTitle] = useState('Add Customers');
    const [, forceUpdate] = useState();
    const [ActiveTab, SetActiveTab] = useState(1);
    const [UserId, SetUserId] = useState(0);

    useEffect(() => {
        async function get_detaile() {
            if (props.match.params.id) {
                setTitle('Edit Customers');
                var res_data = await post(POST_UPDATE_CUSTOMER_DETAIL, { 'companyId': props.match.params.id }, {});
                if (res_data.status) {
                    setInputs(res_data.data.detail);
                } else {
                    toast.error(res_data.message);
                    history.push("/");
                }
                await get_user_list();
            }
            var res_data = await get(POST_GET_ALL_CURRENCIES);
            if (res_data.status) {
                SetCurrenciesList(res_data.data.currencies_list);
            } else {
                toast.error(res_data.message);
            }
            setLoader(1);
        }
        get_detaile();
    }, [props.match.params.id])

    // form submit event
    const handleSubmit = async (event) => {
        event.preventDefault();
        const formValid = simpleValidator.current.allValid()
        if (!formValid) {
            simpleValidator.current.showMessages(true);
            forceUpdate(1);
        } else {
            SetButtonDisabled(true);
            var res_data = await post(POST_ADD_EDIT_CUSTOMER_DETAIL, form_inputs, {});
            if (res_data.status) {
                toast(res_data.message);
                history.push("/customers");
            } else {
                toast.error(res_data.message);
            }
            SetButtonDisabled(false);
        }
    }

    // input text change handler
    const handleInputChange = (event) => {
        event.persist();
        if (event.target.name === 'logo' || event.target.name === 'coverImage') {
            var file_extension = event.target.files[0].name.split('.').pop();
            var file_size = event.target.files[0].size;
            var file_size = (file_size / 1024 / 1024).toFixed(1);

            var extension = ['jpg', 'jpeg'];
            if (!extension.includes(file_extension.toLowerCase())) {
                toast.error('Alludu only file jpg and jpeg.');
                event.target.value = null;
                return false;
            }

            if (file_size > 1) {
                toast.error('Max File size Allowed 1Mb.');
                event.target.value = null;
                return false;
            }

            var file = event.target.files[0];
            var reader = new FileReader();
            reader.onload = async function () {
                setInputs(inputs => ({ ...form_inputs, [event.target.name]: reader.result }));
            }
            reader.readAsDataURL(file);
        } else {
            setInputs(inputs => ({ ...form_inputs, [event.target.name]: event.target.value }));
        }
    }

    // form submit event
    const get_user_list = async () => {
        var res_data = await post(POST_CUSTOMER_USER_LIST, { companyId: props.match.params.id });
        if (res_data.status) {
            SetUserList(res_data.data.user_list);
        } else {
            toast.error(res_data.message);
        }
        SetButtonDisabled(false);
    }

    // delete product
    const Delete = async () => {
        SetButtonDisabled(true);
        var res_data = await post(POST_DELETE_USER, { userId: UserId });
        if (res_data.status) {
            toast(res_data.message);
            window.$('#DeleteUserModal').modal('hide');
            get_user_list();
        } else {
            toast.error(res_data.message);
        }
        SetButtonDisabled(false);
    }

    if (!isLoader) {
        return (<Loader></Loader>);
    }

    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Bizbaron | {title}</title>
            </Helmet>

            <div className='row mt-3'>
                <div className='col-sm-12'>
                    <div className='card'>
                        <div className="card-header">
                            <h6 className='m-0'>{title}</h6>
                        </div>
                        <div className='card-body'>
                            <form className="av-invalid" onSubmit={handleSubmit}>
                                <div class="horizontal">
                                    <ul>
                                        <li><a href="javascript:void(0)" onClick={() => { SetActiveTab(1) }} className={`${ActiveTab === 1 ? "active" : ""}`} >Company Details</a></li>
                                        <li><a href="javascript:void(0)" onClick={() => { SetActiveTab(2) }} className={`${ActiveTab === 2 ? "active" : ""}`}  >Exporter Details</a></li>
                                        <li><a href="javascript:void(0)" onClick={() => { SetActiveTab(3) }} className={`${ActiveTab === 3 ? "active" : ""}`}  >Exporter Bank Details</a></li>
                                        {form_inputs.companyId > 0 && <li><a href="javascript:void(0)" onClick={() => { SetActiveTab(4) }} className={`${ActiveTab === 4 ? "active" : ""}`}  >User List</a></li>}
                                    </ul>
                                </div>
                                {ActiveTab === 1 && <div className='row mt-4'>
                                    <div className='col-sm-6'>
                                        <div className="form-group mb-3">
                                            <label className="form-label text-capitalize">Company Name</label>
                                            <input name="companyName" value={form_inputs.companyName} onChange={handleInputChange} className="form-control" type="text" />
                                            {simpleValidator.current.message('name', form_inputs.companyName, 'required')}
                                        </div>
                                        <div className="form-group mb-3">
                                            <label className="form-label text-capitalize">Company Brand</label>
                                            <input name="companyBrand" value={form_inputs.companyBrand} onChange={handleInputChange} className="form-control" type="text" />
                                        </div>
                                        <div className="form-group mb-3">
                                            <label className="form-label text-capitalize">Working hours</label>
                                            <input name="workingHoursTime" value={form_inputs.workingHoursTime} onChange={handleInputChange} className="form-control" type="text" />
                                        </div>
                                        <div className="form-group mb-3">
                                            <label className="form-label text-capitalize">Select Base Currency</label>
                                            <select name="base_currency" value={form_inputs.base_currency} onChange={handleInputChange} className="form-control" >
                                                <option value="">Select Base Currency</option>
                                                {CurrenciesList && Object.entries(CurrenciesList).map(([key, value]) => (
                                                    <option key={key} value={value.currenciesId}>{value.name} ({value.code})</option>
                                                ))}
                                            </select>
                                            {simpleValidator.current.message('base currency', form_inputs.base_currency, 'required')}
                                        </div>
                                    </div>
                                    <div className='col-sm-6'>
                                        <div className='row mb-3'>
                                            <div className='col-sm-6'>
                                                <div className="form-group mb-3">
                                                    <label className="form-label text-capitalize">Logo</label>
                                                    <input name="logo" className="form-control" onChange={handleInputChange} type="file" />
                                                </div>
                                            </div>
                                            <div className='col-sm-6'>
                                                {form_inputs.logoUrl && <img src={img_view(form_inputs.logoUrl, '60', '60')} />}
                                            </div>
                                        </div>
                                        <div className='row'>
                                            <div className='col-sm-6'>
                                                <div className="form-group mb-3">
                                                    <label className="form-label text-capitalize">Cover image</label>
                                                    <input name="coverImage" className="form-control" onChange={handleInputChange} type="file" />
                                                </div>
                                            </div>
                                            <div className='col-sm-6'>
                                                {form_inputs.coverImageUrl && <img src={img_view(form_inputs.coverImageUrl, '1200', '300')} style={{ 'maxWidth': '100%' }} />}
                                            </div>
                                        </div>
                                        <small>We recommend you to add jpg or jpeg files Logo with 300 x 300 px and Cover image with 1200 x 300 px dimensions</small>
                                    </div>
                                </div>}


                                {ActiveTab === 2 && <div className='row mt-4'>
                                    <div className='col-sm-6'>
                                        <div className="form-group mb-3">
                                            <label className="form-label text-capitalize">Name</label>
                                            <input name="name" value={form_inputs.name} onChange={handleInputChange} className="form-control" type="text" />
                                        </div>
                                        <div className="form-group mb-3">
                                            <label className="form-label text-capitalize">Email Id</label>
                                            <input name="emailId" value={form_inputs.emailId} onChange={handleInputChange} className="form-control" type="text" />
                                        </div>
                                        <div className="form-group mb-3">
                                            <label className="form-label text-capitalize">Other reference</label>
                                            <textarea name="otherReference" rows={5} value={form_inputs.otherReference} onChange={handleInputChange} className="form-control" type="text" />
                                        </div>
                                        <div className="form-group mb-3">
                                            <label className="form-label text-capitalize">Address</label>
                                            <textarea name="address" rows={5} value={form_inputs.address} onChange={handleInputChange} className="form-control" type="text" />
                                        </div>
                                    </div>
                                    <div className='col-sm-6'>
                                        <div className="form-group mb-3">
                                            <label className="form-label text-capitalize">Country of origin</label>
                                            <input name="countryOfOrigin" value={form_inputs.countryOfOrigin} onChange={handleInputChange} className="form-control" type="text" />
                                        </div>
                                        <div className="form-group mb-3">
                                            <label className="form-label text-capitalize">Exporter reference ID (IEC Code)</label>
                                            <input name="exporterReferenceID" value={form_inputs.exporterReferenceID} onChange={handleInputChange} className="form-control" type="text" />
                                        </div>
                                        <div className="form-group mb-3">
                                            <label className="form-label text-capitalize">Terms & Conditions</label>
                                            <textarea name="termsAndConditions" rows={5} value={form_inputs.termsAndConditions} onChange={handleInputChange} className="form-control" type="text" />
                                        </div>
                                    </div>
                                </div>}

                                {ActiveTab === 3 && <div className='row mt-4'>
                                    <div className="col-sm-6 form-group mb-3">
                                        <label className="form-label text-capitalize">Bank Name</label>
                                        <input name="bankName" value={form_inputs.bankName} onChange={handleInputChange} className="form-control" type="text" />
                                    </div>
                                    <div className="col-sm-6 form-group mb-3">
                                        <label className="form-label text-capitalize">Account No</label>
                                        <input name="accountNumber" value={form_inputs.accountNumber} onChange={handleInputChange} className="form-control" type="text" />
                                    </div>
                                    <div className="col-sm-6 form-group mb-3">
                                        <label className="form-label text-capitalize">IFSC Code</label>
                                        <input name="IFSCCode" value={form_inputs.IFSCCode} onChange={handleInputChange} className="form-control" type="text" />
                                    </div>
                                    <div className="col-sm-6 form-group mb-3">
                                        <label className="form-label text-capitalize">SWIFT Code</label>
                                        <input name="SWIFTCode" value={form_inputs.SWIFTCode} onChange={handleInputChange} className="form-control" type="text" />
                                    </div>
                                    <div className="col-sm-6 form-group mb-3">
                                        <label className="form-label text-capitalize">Address</label>
                                        <textarea name="bankAddress" rows={5} value={form_inputs.bankAddress} onChange={handleInputChange} className="form-control" />
                                    </div>
                                </div>}

                                {ActiveTab === 4 && <div className='row mt-4'>
                                    <div className='col-sm-12 mt-3 mb-3 text-right'>
                                        <button type='button' onClick={() => { SetUserId(0); window.$('#UserAddEditModal').modal({ backdrop: 'static', keyboard: false }); }} className='btn btn-primary c-btn-primary pt-1 pb-1 pl-3 pr-3'>+ Add</button>
                                    </div>
                                    <div className='col-sm-12'>
                                        <table className='table table-striped'>
                                            <thead>
                                                <tr>
                                                    <td>First Name</td>
                                                    <td>Last Name</td>
                                                    <td>Email</td>
                                                    <td>Mobile Number</td>
                                                    <td>Reg Date</td>
                                                    <td>Status</td>
                                                    <td>Action</td>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {UserList && Object.entries(UserList).map(([key, value]) => (
                                                    <tr key={key}>
                                                        <td>{value.firstName}</td>
                                                        <td>{value.lastName}</td>
                                                        <td>{value.emailId}</td>
                                                        <td>+{value.mobileNumber}</td>
                                                        <td>{utc_to_local(value.createdAt)}</td>
                                                        <td>{value.userStatus}</td>
                                                        <td>
                                                            <button type='button' className="btn btn-sm btn-primary pt-0 pb-0 pl-1 pr-1  mr-2" onClick={() => { SetUserId(value.userId); window.$('#UserAddEditModal').modal({ backdrop: 'static', keyboard: false }); }}><i className="fa fa-pencil" style={{ "fontSize": "12px" }} aria-hidden="true"></i></button>
                                                            <button type='button' class="btn btn-sm btn-danger pt-0 pb-0 pl-1 pr-1  mr-2" onClick={() => { SetUserId(value.userId); }} data-toggle="modal" data-target="#DeleteUserModal" ><i class="fa fa-trash-o" style={{ 'font-size': '12px' }} aria-hidden="true"></i></button>
                                                        </td>
                                                    </tr>
                                                ))}

                                                {UserList.length <= 0 && <tr>
                                                    <td colSpan={7} className="text-center">No any user</td>
                                                </tr>}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>}
                                {ActiveTab !== 4 && <>
                                    <hr />
                                    <div className="text-center mt-4">
                                        <button disabled={ButtonDisabled} type="submit" className='btn btn-primary c-btn-primary pt-1 pb-1 pl-3 pr-3'>{ButtonDisabled && <i className="fa fa-spinner me-2 fa-spin"></i>} Submit</button>
                                        <Link to="/customers" className='btn btn-danger c-btn-danger ml-3 pt-1 pb-1 pl-3 pr-3'>Cancel</Link>
                                    </div>
                                </>}
                            </form>
                            <UsersModal userId={UserId} companyId={form_inputs.companyId} get_user_list={get_user_list} />
                            
                            <div className="modal fade" id="DeleteUserModal" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                                <div className="modal-dialog" role="document">
                                    <div className="modal-content">
                                        <div className="modal-header">
                                            <h5 className="modal-title" id="exampleModalLabel">Confirm Delete</h5>
                                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                                <span aria-hidden="true">&times;</span>
                                            </button>
                                        </div>
                                        <div className="modal-body">
                                            <p>Are you sure you want to delete this record?</p>
                                        </div>
                                        <div className="modal-footer">
                                            <button type="button" disabled={ButtonDisabled} className="btn btn-sm btn-dnager c-btn-danger text-white" data-dismiss="modal">No</button>
                                            <button onClick={Delete} disabled={ButtonDisabled} data-dismiss="modal" className="btn btn-sm btn-primary c-btn-primary">{ButtonDisabled && <i className="fa fa-spinner me-2 fa-spin"></i>} Yes</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
export default withRouter(ChangePassword)
