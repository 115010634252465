import React, { useState, useEffect } from 'react';
import { withRouter } from "react-router-dom";
import { get } from '../../helpers/api_helper';
import { GET_DASHBOARD_DATA } from '../../helpers/url_helper';
import { toast } from 'react-toastify';

const Dashboard = props => {
    const [totalDesigner, SetTotalDesigner] = useState(0);
    const [totalUser, SetTotalUser] = useState(0);
    // const [totalComplain, SetTotalComplain] = useState(0);

    useEffect(() => {
        async function get_detaile() {
            await get_service();
        }
        get_detaile();
    }, [])

    const get_service = async () => {
        // var res_data = await get(GET_DASHBOARD_DATA, {});
        // if (res_data.status) {
        //     SetTotalDesigner(res_data.total_designer)
        //     SetTotalUser(res_data.total_user)
        //     // SetTotalComplain(res_data.total_complain)
        // } else {
        //     toast.error(res_data.message);
        // }
    }
    return (
        <>
            <div className='row'>
                <div className='col-sm-12'>
                    <h5>Welcome To Bizbaron</h5>
                </div>
            </div>
            <div className='row mt-15 none'>
                <div className='col-sm-12 col-md-6 col-lg-3 mb-3'>
                    <div className='card'>
                        <div class="card-body text-center">
                            <h6>Total Designer</h6>
                            <h6>{totalDesigner}</h6>
                        </div>
                    </div>
                </div>
                <div className='col-sm-12 col-md-6 col-lg-3 mb-3'>
                    <div className='card'>
                        <div class="card-body text-center">
                            <h6>Total User</h6>
                            <h6>{totalUser}</h6>
                        </div>
                    </div>
                </div>
                {/* <div className='col-sm-12 col-md-6 col-lg-3 mb-3'>
                    <div className='card'>
                        <div class="card-body text-center">
                            <h6>Total Pending Complain</h6>
                            <h6>{totalComplain}</h6>
                        </div>
                    </div>
                </div> */}
            </div>
        </>
    );
}

export default withRouter(Dashboard);
