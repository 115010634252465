import React from 'react';
import { NavLink } from "react-router-dom";

import FeatherIcon from "feather-icons-react";
export default function Header() {

    return (
        <div className='left_nav_bar'>
            <ul className='sidebar-menu'>
                <li>
                    <NavLink exact to="/" className="has-arrow">
                        <FeatherIcon icon="home" width="22" />
                        <span>Dashboard</span>
                    </NavLink>
                </li>
                {/* <li>
                    <a href="#homeSubmenu" data-toggle="collapse" aria-expanded="false" class="dropdown-toggle" >
                        <FeatherIcon icon="users" width="22" />
                        <span>User</span>
                    </a>
                    <ul class="collapse list-unstyled" id="homeSubmenu">
                        <li>
                            <NavLink to="/users">Users</NavLink>
                        </li>
                    </ul>
                </li> */}

                <li>
                    <NavLink to="/customers" >
                        <FeatherIcon icon="users" width="22" />
                        <span>Customers</span>
                    </NavLink>
                </li>
               
                <li>
                    <NavLink exact to="/setting" className="has-arrow">
                        <FeatherIcon icon="settings" width="22" className="mt-1" />
                        <span>Setting</span>
                    </NavLink>
                </li>
            </ul>
        </div>
    );
}