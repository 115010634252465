import React from 'react';
import { Switch } from 'react-router-dom';
import AppRoute from './layouts/App_route';
import UserRoute from './layouts/UserRoute';

import { BrowserRouter } from 'react-router-dom';

import Default from './layouts/Default';
import Full from './layouts/Full';

// pages
import Dashboard from './pages/Dashboard/Dashboard';
import Login from './pages/Auth/Login';
import Logout from "./pages/Auth/Logout";
import ForgetPassword from "./pages/Auth/ForgetPassword";
import ChangePassword from "./pages/Auth/ChangePassword";

//CustomersList
import CustomersList from "./pages/Customers/CustomersList";
import CustomersForm from "./pages/Customers/CustomersForm";

// setting
import Setting from "./pages/Setting/Setting";
import EmailSetting from "./pages/Setting/EmailSetting";
import CommonSetting from "./pages/Setting/CommonSetting";

function App() {
    return (
        <>
        <BrowserRouter>
            <Switch>
                <AppRoute exact path="/login" component={Login} layout={Full} />
                <AppRoute exact path="/logout" component={Logout} layout={Full} />
                <AppRoute exact path="/forgot-password" component={ForgetPassword} layout={Full} />
                
                <UserRoute exact path="/change-password" component={ChangePassword} layout={Default} />

                {/* Customers */}
                <UserRoute exact path="/customers" component={CustomersList} layout={Default} />
                <UserRoute exact path="/customers/add" component={CustomersForm} layout={Default} />
                <UserRoute exact path="/customers/edit/:id" component={CustomersForm} layout={Default} />

                {/* Setting */}
                <UserRoute exact path="/setting" component={Setting} layout={Default} />
                <UserRoute exact path="/setting/email" component={EmailSetting} layout={Default} />
                <UserRoute exact path="/setting/common" component={CommonSetting} layout={Default} />

                <UserRoute exact path="/" component={Dashboard} layout={Default} />
            </Switch>
        </BrowserRouter> 
    </>
  );
}

export default App;
